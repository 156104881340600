const initBlock = async function( block ) {
	const slider = block.find( ".js-reviews-slider" )[0];if ( !slider ) {
		return;
	}

	const { Swiper } = await import( "swiper" );
	const { Navigation, Pagination } = await import( "swiper/modules" );
	await import( "swiper/css/bundle" );

	new Swiper( slider, {
		slidesPerView: 1,
		simulateTouch: false,
		breakpoints: {
			768: {
				slidesPerView: 1.25,
			},
			992: {
				slidesPerView: 2,
			},
			1200: {
				slidesPerView: 2.25,
			}
		},
		modules: [Navigation, Pagination],
		navigation: {
			prevEl: block.find( "[data-prev-el]" )[0],
			nextEl: block.find( "[data-next-el]" )[0],
		},
		pagination: {
			el: block.find( "[data-pagination-el]" )[0],
			type: "fraction"
		}
	} );
};

themeUtils.loadBlock( initBlock, "reviews", ".section-reviews" );
